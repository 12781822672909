import React from 'react';
import ChevronRightXs from '../../icons/ChevronRightXs.svg';

const PageCard = ({ page }) => {
	const { header, title, slug } = page;
	return (
		<div className="w-full bg-secondary-100">
			{header[0] && (
				<a href={`/esg/${slug}/`}>
					<img
						src={header[0].backgroundImage.url}
						alt={header[0].backgroundImage.alt || ''}
						className="!h-[220px] object-cover"
						style={{ objectPosition: '35% 50%' }}
					/>
				</a>
			)}
			<div className="p-4">
				<h4 className="pb-4 text-3xl">{title}</h4>
				{header[0] && <p className=" min-h-[245px]">{header[0].description}</p>}

				<div className="py-4">
					<a
						href={`/esg/${slug}/`}
						className="font-bold hover:scale-105 bg-white border-2 border-brand-300 rounded-[50px] text-brand-300 px-4 py-2 text-sm mt-4"
					>
						More on {title}
						<span className="w-3 h-3 mt-1 ml-2 align-text-bottom">
							<ChevronRightXs className="inline-block" />
						</span>
					</a>
				</div>
			</div>
		</div>
	);
};

export default PageCard;
