import React from 'react';
import { MDXProvider } from '@mdx-js/react';

import defaultProviderComponents from './defaultProviderComponents';
import Heading from './components/Heading';
import Text from './components/Text';

const QuoteProvider = ({ children }) => (
	<MDXProvider
		components={{
			...defaultProviderComponents,
			h3: (props) => <Heading {...props} fontWeight="font-normal" level="h3" />,
			p: (props) => <Text {...props} fontSize="text-xl" />,
		}}
	>
		{children}
	</MDXProvider>
);

export default QuoteProvider;
