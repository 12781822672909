import React from 'react';
import { useLocation } from '@reach/router';
import { graphql } from 'gatsby';
import { nanoid } from 'nanoid';
import Container from '../components/Container';
import DefaultLayout from '../layouts/Default';
import DatoSEO from '../components/DatoSEO';
import CopyBlock from '../components/CopyBlock';
import QuoteBlock from '../components/CMSBlocks/QuoteBlock';
import PageCard from '../components/PageCard';
import Hero from '../components/CMSBlocks/Hero';

const ESGHomepage = ({ data }) => {
	const { hero, introNode, quote, pages, seoMetaTags } = data.allDatoCmsEscgHomepage.nodes[0];
	const location = useLocation();

	const pagesOrder = ['Environmental', 'Social', 'Community', 'Governance'];
	const sortedPages = [
		...new Set(pages.sort((a, b) => pagesOrder.indexOf(a.title) - pagesOrder.indexOf(b.title))),
	];

	return (
		<div>
			<DatoSEO path={location.pathname} meta={seoMetaTags} />
			<Hero
				bgImg={hero[0].backgroundImage.url}
				title={hero[0].heroTitle}
				fullWidth
				breadcrumbs={{
					currentPage: hero[0].heroTitle,
				}}
			/>
			<section className="flex flex-col md:block">
				<Container className="md:px-3 lg:p-6">
					<div className="py-8">
						<CopyBlock>{introNode.childMdx.body}</CopyBlock>
					</div>
				</Container>
			</section>
			<section className="bg-secondary-100">
				<div className="container px-3 mx-auto overflow-visible md:px-6">
					<QuoteBlock quoteText={quote[0].quoteTextNode} person={quote[0].person} />
				</div>
			</section>
			<section className="py-6" id="esgPages">
				<Container>
					<h3 className="py-8 text-4xl text-center">Our ESG commitments</h3>
					<div className="grid grid-cols-1 md:gap-3 md:grid-cols-2 xl:grid-cols-4">
						{sortedPages.map((page) => (
							<PageCard page={page} key={nanoid()} />
						))}
					</div>
				</Container>
			</section>
		</div>
	);
};

const WrappedESGHomepage = (props) => (
	<DefaultLayout fullWidth>
		<ESGHomepage {...props} />
	</DefaultLayout>
);

export default WrappedESGHomepage;

export const query = graphql`
	query ESCGHomepage {
		allDatoCmsEscgHomepage {
			nodes {
				hero {
					heroTitle
					heroText
					backgroundImage {
						url
					}
				}
				seoMetaTags {
					...GatsbyDatoCmsSeoMetaTags
				}
				introNode {
					childMdx {
						body
					}
				}
				quote {
					quoteTextNode {
						childMdx {
							body
						}
					}
					person {
						personName
						personTwitter
						personTwitterHandle
						personImage {
							gatsbyImageData(
								layout: CONSTRAINED
								imgixParams: { auto: "compress" }
								height: 580
								width: 580
							)
						}
						personBio
						companyPositionFullTitle
						companyPosition
						company
						position
						spokesperson
					}
				}
				pages {
					title
					slug
					header {
						backgroundImage {
							url
							alt
						}
						copyNode {
							childMdx {
								body
							}
						}
						description
					}
					imageWithCopy {
						backgroundColour
						imagePosition
						imageWithCopyImage {
							gatsbyImageData
							alt
						}
					}
					quoteWithHeadshot {
						person {
							associatedCountries
							company
							companyPosition
							companyPositionFullTitle
							country
							personBio
							personName
							personTwitter
							personBioNode {
								childMdx {
									body
								}
							}
							personTwitterHandle
						}
					}
				}
			}
		}
	}
`;
